var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"33fbf5679c4fc3a7f4891b33fa4bf5f17bfffef8"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	dsn:
		SENTRY_DSN ||
		'https://3de5ee905209403b8e9ebc2988000521@o910480.ingest.sentry.io/5849364',
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 0.1,
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
	allowUrls: [
		'app.procurement.myvintro.com',
		'app.opennation.co.uk',
		'preview.myvintro.com',
		'dnb-preview.myvintro.com',
		'open-nation-preview.myvintro.com',
	],
	ignoreErrors: ['TypeError: cancelled'],
});
